export const heroDataA = {
  headingTop: 'LIAM CREST CORPORATION',
  headingPara: 'An ELearning Development Company',
  buttonText: 'GET IN TOUCH',
  image: '../img1.png',
};

export const heroDataB = {
  headingTop: 'GRANT PROGRAM',
  headingPara: 'NEED HELP FINANCING YOUR ELEARNING PROJECT',
  buttonText: 'GET IN TOUCH',
  image: "../topsection.png",
};
