import styled from "styled-components";

export const StyledMain = styled.div`
  width: 100%;
  // height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("back12.png"), #F4F8F9  ;
  position: relative;
  z-index: 0;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(244, 248, 249, 1);
    // background: linear-gradient(
    //   180deg,
    //   rgba(3, 55, 94, 0.99) 0%,
    //   rgba(30, 57, 77, 0.99) 100%
    // );
    z-index: 1;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
  }
`;
export const StyledTextSection = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 2%;
  position: relative;
  left: -7%;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    margin-right: 1%;
    width: 45%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    margin-right: 1%;
    width: 45%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 65%;
    align-items: center;
    justify-content: center;
    left: 0;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 98%;
    align-items: center;
    justify-content: center;
    left: 0;
  }
`;
export const Logo = styled.img`
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 24px !important;
    height: 24px !important;
  }
`;

export const StyledButton2 = styled.button`
  width: 275.375px;
  height: 64.679px;
  flex-shrink: 0;
  border-radius: 33px;
  background: #03375e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
`;
export const DataFirst = styled.div``;
export const DataSecond = styled.div`
  width: 80%;
`;
export const StyledCarosuel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  .rec-arrow {
    color: #000000;
    border: 1px solid #03375e;
    background: transparent !important;
    z-index: 0;
    margin-left: 10px;
    margin-right: 40px;
    @media only screen and (min-width: 1281px) and (max-width: 1536px) {
      margin-right: 0px;
    }
    @media only screen and (min-width: 1081px) and (max-width: 1280px) {
      margin-right: 0px;
      margin-left: 0;
    }
    @media only screen and (min-width: 811px) and (max-width: 1080px) {
      margin-left: 0;
      margin-right: 0;
    }
    @media only screen and (min-width: 641px) and (max-width: 810px) {
      margin-left: 0;
      margin-right: 0;
      display: none;
    }
    @media only screen and (min-width: 320px) and (max-width: 640px) {
      margin-left: 0;
      margin-right: 0;
      display: none;
    }
  }
  .rec-arrow:hover {
    color: #ffffff;
    background: #03375e !important ;
  }

  .rec-arrow.rec-arrow-disabled {
    color: white;
    background: #ef991b;
  }
`;
export const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
`;
export const StyledP = styled.p`
  color: #fc9400;
  text-align: center;
  font-family: Montserrat;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 39px */
  letter-spacing: -0.91px;
  margin: 0.2rem;
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    font-size: 24px;
    letter-spacing: -0.84px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 20px;
    letter-spacing: -0.7px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 16px;
    letter-spacing: -0.56px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 16px;
    letter-spacing: -0.56px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 14px;
    letter-spacing: -0.49px;
  }
`;
export const StyledTitle = styled.h3`
  width: 100%;
  color: #03375e;
  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 105%; /* 50.4px */
  letter-spacing: -1.92px;
  margin: 0.5rem;

  span {
    color: #f19101;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    font-size: 31px;
    letter-spacing: -1.68px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 36px;
    letter-spacing: -1.6px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 30px;
    letter-spacing: -1.28px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 32px;
    letter-spacing: -1.28px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 26px;
    letter-spacing: -1.04px;
  }
`;
export const StyledServices = styled.div`
  width: 115%;
  // height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  margin-left: -8%;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    margin-left: -8%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    margin-left: 2%;
    width: 90%;
  }
`;
export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    position: relative;
    gap: 14px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-content: center;
    margin-top: 0%;
    width: 83%;
  }
`;
export const StyledRow1 = styled.div`
  display: none;
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    display: block;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: initial;
    margin-top: 2%;
    gap: 5%;
  }
`;
export const StyledCard = styled.div`
  width: 22%;
  height: 290px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid rgba(216, 216, 216, 0.73);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: transform 1s ease;
  gap: 40px;
  position: relative;
  z-index: 0;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../cardimage.png") center/cover;
    z-index: -1;
  }
  &:hover {
    transform: translateY(-2%);
    width: 22%;
    height: 290px;
    // transform: rotate(-90deg);
    flex-shrink: 0;
    border-radius: 3px;
    background: #03375e;
    mix-blend-mode: multiply;
    box-shadow: 0px 42px 30.3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 243px;
    &:hover {
      height: 243px;
    }
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 208px;
    gap: 20px;
    &:hover {
      height: 208px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 157px;
    gap: 20px;
    &:hover {
      height: 157px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 160.535px;
    width: 34%;
    gap: 20px;
    &:hover {
      width: 34%;
      height: 160px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
    height: 105px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 2%;
    &:hover {
      width: 90%;
      height: 105px;
    }
  }
`;
export const StyledCardRow = styled.div`
  width: 22%;
  height: 290px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 1s ease;
  border: 1px solid rgba(216, 216, 216, 0.73);
  border-radius: 10px;
  gap: 40px;
  position: relative;
  z-index: 0;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../cardimage.png") center/cover;
    z-index: -1;

  }
  &:hover {
    transform: translateY(-2%);
    width: 22%;
    height: 290px;
    // transform: rotate(-90deg);
    flex-shrink: 0;
    border-radius: 3px;
    background: #03375e;
border-radius: 10px;
    mix-blend-mode: multiply;
    box-shadow: 0px 42px 30.3px 0px rgba(0, 0, 0, 0.12);
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    height: 243px;
    &:hover {
      height: 243px;
    }
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    height: 208px;
    gap: 20px;
    &:hover {
      height: 208px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 157px;
    gap: 20px;
    &:hover {
      height: 157px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 34%;
    height: 157px;
    gap: 20px;
    &:hover {
      width: 34%;
      height: 157px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
    height: 105px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 2%;
    &:hover {
      width: 90%;
      height: 105px;
    }
  }
`;
export const DataThird=styled.div`
width:80%;
`;
export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128.564px;
  height: 128.564px;
  flex-shrink: 0;
  border-radius: 50%;
  border: none;
  background: #dcedf4;
  ${StyledCard}:hover & {
    border: 1px solid #000000;
  }
  ${StyledCardRow}:hover & {
    border: 1px solid #000000;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1536px) {
    width: 115px;
    height: 115px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    width: 90px;
    height: 90px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 64px;
    height: 66px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 65.441px;
    height: 67.486px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 46px;
    height: 46px;
    margin-left: 5%;
    align-items: center;
    margin-top: 5%;
  }
`;
export const StyledCardBackground = styled.div`
  width: 69px;
  height: 69px;
  flex-shrink: 0;
  color: #dcedf4;
  ${StyledCard}:hover & {
    color: #fff;
    width: 81.327px;
    height: 81.327px;
  }
  ${StyledCardRow}:hover & {
    color: #fff;
    width: 81.327px;
    height: 81.327px;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 57px;
    height: 58px;
    ${StyledCard}:hover & {
      width: 68.143px;
      height: 68.143px;
    }
    ${StyledCardRow}:hover & {
      width: 68.143px;
      height: 68.143px;
    }
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    width: 49px;
    height: 49px;
    ${StyledCard}:hover & {
      width: 57.143px;
      height: 57.143px;
    }
    ${StyledCardRow}:hover & {
      width: 57.143px;
      height: 57.143px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 35px;
    height: 37px;
    ${StyledCard}:hover & {
      width: 43.143px;
      height: 43.143px;
    }
    ${StyledCardRow}:hover & {
      width: 43.143px;
      height: 43.143px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 35.788px;
    height: 37.833px;
    ${StyledCard}:hover & {
      width: 44.374px;
      height: 44.374px;
    }
    ${StyledCardRow}:hover & {
      width: 44.374px;
      height: 44.374px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 37px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${StyledCard}:hover & {
      width: 43.143px;
      height: 43.143px;
    }
    ${StyledCardRow}:hover & {
      width: 43.143px;
      height: 43.143px;
    }
  }
`;
export const StyledPara = styled.div`
  color: #03375e;
  width: 80%;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 131.5%; /* 26.3px */
  letter-spacing: -0.8px;
  ${StyledCard}:hover & {
    color: #fff;
  }
  ${StyledCardRow}:hover & {
    color: #fff;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 18px;
    letter-spacing: -0.72px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    font-size: 15px;
    letter-spacing: -0.72px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 11px;
    letter-spacing: -0.48px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 11px;
    letter-spacing: -0.48px;
    width: 100%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 9px;
    letter-spacing: -0.48px;
    width: 75%;
  }
`;