import styled from "styled-components";

export const InfoSecTop=styled.div`
background-image: url('../bg.png');
width:100%;
background-size: cover;
background-repeat: no-repeat;
display: flex;
flex-direction: column;
height:   100vh; 
overflow:hidden ;
margin:0;
padding:0;
@media only screen and (min-width:1281px) and (max-width:1536px){
  height:100vh;
}
@media only screen and (min-width:810px) and (max-width:1080px){
  height:72vh;
}
@media only screen and (min-width:641px) and (max-width:810px){
  height:68vh;
}
@media only screen and (min-width:320px) and (max-width:640px){
  height:55vh;

}
`;

export const InfoDiv1=styled.div`

`;
export const StyledImage=styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
 margin-top: 14.5%;
 @media only screen and (min-width:1281px) and (max-width:1536px){
  margin-top: 14.5%;
 
 }
 @media only screen and (min-width:641px) and (max-width:810px){
  margin-top:21%;
 
 }
 @media only screen and (min-width:320px) and (max-width:640px){
  margin-top:-16%;
 
 }
`;
export const Image=styled.img`
  width: 44%;
  height: 63%;
  position: absolute;
  bottom:0;

  @media only screen and (min-width:1281px) and (max-width:1536px){
    width: 44%;
  height: 63%;
 
}
@media only screen and (min-width:1081px) and (max-width:1280px){
  width: 44%;
  height: 58%;
 }
 @media only screen and (min-width:811px) and (max-width:1080px){
  width: 44%;
  height: 372px;
  bottom: 26%;
 }
 @media only screen and (min-width:641px) and (max-width:810px){
  width: 44%;
  height: 259px;
  bottom: 30%;
 }
 @media only screen and (min-width:320px) and (max-width:640px){
  width: 42%;
  height: 146px;
bottom: 42%;
 }
`;
export const Heading=styled.div`

display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
width:100%;
height:100%;
margin-top: -8%;
position: relative;
top:-21%;
min-height: 82vh;
@media only screen and (min-width:1281px) and (max-width:1536px){
  margin-top: -10%;
  position:relative;
  top:-21%;
  min-height: 84vh;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
  min-height: 88vh;
  margin-top: -6%;
  top:-16%;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  min-height: 90vh;
  top:-21%;
}
@media only screen and (min-width:641px) and (max-width:810px){
  min-height: 91vh;
  z-index:0;
  top:-17%;
}
@media only screen and (min-width:320px) and (max-width:640px){
  min-height: 108vh;
  z-index:0;
  top:-11%;
}
`;
export const HeadingTitle=styled.h2`
font-family: 'Montserrat';
position: relative;
z-index:1;
text-align: center;
font-weight: 500;
font-size:24px;
line-height: 31.2px;
align-items: center;
background-image: linear-gradient(144.3deg, #F19101 20.9%, #FFFFFF 93.48%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
@media only screen and (min-width:1281px) and (max-width:1536px){
  font-size:18px;
  line-height: 23.4px;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
  font-size:20px;
  line-height: 26.4px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  font-size:16px;
  line-height: 20.4px;
}
@media only screen and (min-width:641px) and (max-width:810px){
  font-size:14px;
  line-height: 18.4px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size:10px;
  line-height: 13.4px;
}
`;
export const HeadingPara=styled.h1`
font-family: 'Montserrat';
position: relative;
z-index:1;
text-transform: uppercase;
font-weight: 700;
font-size:70px;
line-height: 70px;
align-items: center;
color:#FFFFFF;
width:64% ;
transform: translateY(-22%);
text-align: center;
@media only screen and (min-width:1281px) and (max-width:1536px){
  font-size:52px;
  line-height: 48px;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
  font-size:60px;
  line-height:60px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  width:60%;
  font-size:40px;
  line-height: 46px;
}
@media only screen and (min-width:641px) and (max-width:810px){
  width:60%;
  font-size:36px;
  line-height: 40px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  width:80%;
  font-size:24px;
  line-height: 24px;
}
`;
export const ButtonText=styled.div`
display: flex;
position: relative;
z-index:1;
align-items: center;
justify-content: center;
text-align: center;
transform: translateY(-80%);
@media only screen and (min-width:1281px) and (max-width:1536px){
  transform: translateY(-80%);
}
@media only screen and (min-width:811px) and (max-width:1080px){
  transform: translateY(-70%);
}
`;
export const Btn=styled.h6`
width:230px;
height:55px;
font-size:20px;
line-height: 26px;
text-align: center;
font-family:'Montserrat';
font-weight:600;
background:#EE991C;
border:none;
color:#FFFFFF;
display: flex;
align-items: center;
justify-content: center;
border-radius:27.5px;
cursor: pointer;
&:hover{
  background: #03375E;
}
@media only screen and (min-width: 1281px) and (max-width:1536px){
  width:164px;
  height:34px;
  font-size:13px;
  line-height: 16.9px;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
  width:251px;
  height:55px;
  font-size:20px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  width:188px;
  height:42px;
  font-size:16px;
}
@media only screen and (min-width:641px) and (max-width:810px){
  width:151px;
  height:33.8px;
  font-size:14px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  width:101px;
  height:22.8px;
  font-size:10px;
}
`;

export const HeadingSec=styled.div`

display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
width:100%;
height:100%;
position: relative;
top:14%; 
margin-top:6%;
@media only screen and (min-width: 1424px) and (max-width: 1536px){
  margin-top:10%;   
  top: 0; 
}
@media only screen and (min-width:1281px) and (max-width:1423px){
margin-top:12%; 
top:0%; 
}
@media only screen and (min-width:1081px) and (max-width:1280px){
margin-top:12%; 
top:11%; 
}
@media only screen and (min-width:811px) and (max-width:1080px){
  margin-top:12%; 
top:11%; 
}
@media only screen and (min-width:641px) and (max-width:810px){
  margin-top:12%; 
top:5%; 
}
@media only screen and (min-width:320px) and (max-width:640px){
  margin-top:10%; 
top:4%; 
}

`;
export const HeadingxTitle=styled.h2`
font-family: 'Montserrat';
position: relative;
z-index:1;

font-weight: 500;
font-size:24px;
line-height: 31.2px;
align-items: center;
color:#F19101;
position: relative;
top:8%;
text-align: center;
@media only screen and (min-width:1281px) and (max-width:1536px){

}
@media only screen and (min-width:1081px) and (max-width:1280px){
font-size:20px;
line-height: 26px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
font-size:16px;
line-height: 20.8px;
}
@media only screen and (min-width:641px) and (max-width:810px){
font-size:14px;
line-height: 18.8px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size:10px;
  line-height: 13.4px;
}
`;
export const HeadingPara1=styled.h1`
font-family: 'Montserrat';
position: relative;
z-index:1;
font-weight: 700;
font-size:70px;
line-height: 70px;
align-items: center;
color:#FFFFFF;
width:70% ;
text-align: center;
/* transform: translateY(-22%); */
@media only screen and (min-width:1281px) and (max-width:1536px){
  font-size:52px;
  line-height:50px;
  width:60%;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
  font-size:60px;
  line-height:60px;
  width:77%;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  width:80%;
  font-size:40px;
  line-height: 46px;
}
@media only screen and (min-width:641px) and (max-width:810px){
  width:72%;
  font-size:36px;
  line-height: 40px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  width:70%;
  font-size:24px;
  line-height: 24px;
}
`;
export const ButtonHeading=styled.div`
display: flex;
position: relative;
z-index:1;
align-items: center;
justify-content: center;
text-align: center;
transform: translateY(-60%);
@media only screen and (min-width:1281px) and (max-width:1536px){
  transform: translateY(-50%);
}
@media only screen and (min-width:1081px) and (max-width:1280px){
  transform: translateY(-52%);
}
@media only screen and (min-width:811px) and (max-width:1080px){
  transform: translateY(-48%);
}
@media only screen and (min-width:641px) and (max-width:810px){
  transform: translateY(-44%);
}
@media only screen and (min-width:320px) and (max-width:640px){
  transform: translateY(-44%);
}
`;
export const Button=styled.h6`
width:230px;
height:55px;
font-size:20px;
line-height: 26px;
text-align: center;
font-family:'Montserrat';
font-weight:600;
background:#EE991C;
border:none;
color:#FFFFFF;
display: flex;
align-items: center;
justify-content: center;
border-radius:27.5px;
cursor: pointer;
&:hover{
  background: #03375E;
}
@media only screen and (min-width:1281px) and (max-width:1536px){
width:164px;
height:34px;
font-size:13px;
line-height: 16.9px;
}
@media only screen and (min-width:1080px) and (max-width:1280px){
  width:251px;
height:55px;
font-size:20px;

  }
  @media only screen and (min-width:811px) and (max-width:1080px){
  width:188px;
  height:42px;
  font-size:16px;
}
@media only screen and (min-width:641px) and (max-width:810px){
  width:151px;
  height:33.8px;
  font-size:14px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  width:101px;
  height:22.8px;
  font-size:10px;
}
`;
export const StyleImage=styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position:relative;
  top:61%;
  @media only screen and (min-width:1537px) and (max-width:1603px){
    top:61%;
  }
  @media only screen and (min-width:1424px) and (max-width:1536px){
    top:66%;
  }
  @media only screen and (min-width:1281px) and (max-width:1423px){
    top:58%;
  }
  
  @media only screen and (min-width:1081px) and (max-width:1280px){
    top:74%;
  }
  @media only screen and (min-width:901px) and (max-width:1080px){
    top:89%;
  }
  @media only screen and (min-width:811px) and (max-width:900px){
    top:82%;
  }
  @media only screen and (min-width:750px) and (max-width:810px){
    top:70%;
  }
  @media only screen and (min-width:641px) and (max-width:749px){
    top:66%;
  }
  @media only screen and (min-width:503px) and (max-width:640px){
    top:62%;
  }
  @media only screen and (min-width:453px) and (max-width:502px){
    top:58%;
  }
  @media only screen and (min-width:401px) and (max-width:452px){
    top:58%;
  }
  @media only screen and (min-width:320px) and (max-width:400px){
    top:56%;
  }
  
`;
export const CenterImage=styled.img`
 width: 985px;
  height: 373px;
  position: absolute;
  bottom:0;
@media only screen and (min-width:1281px) and (max-width:1536px){
  width: 81%;
  height: 373px;
}
  @media only screen and (min-width:1081px) and (max-width:1280px){
  width: 90%;
  height: 432px;
 }
 @media only screen and (min-width:811px) and (max-width:1080px){
  width: 90%;
  height: 372px;
 }
 @media only screen and (min-width:641px) and (max-width:810px){
  width: 85%;
  height: 259px;
 }
 @media only screen and (min-width:320px) and (max-width:640px){
  width: 85%;
  height: 146px;
 }


`;
export const DataFirst=styled.div``;
export const DataSecond=styled.div`
@media only screen and (min-width:1281px) and (max-width:1536px){
width:100%;
margin-top:5%;
}
`;
export const StyledCarosuel=styled.div`

.rec-arrow {
  color: white;
  border:1px solid #FFFFFF;
  background: transparent !important;
z-index: 0; 
   margin-left:30px;
   margin-right:30px;
  margin-top: 0%;
  @media only screen and (min-width:811px) and (max-width:1080px){
    margin-top: -25%;
  }
}
.rec-arrow:hover {
  color: #FFFFFF;
  background: #EF991B !important ;

}


.rec-arrow.rec-arrow-disabled {
  color: white; 
  background: #EF991B;
}


`;
