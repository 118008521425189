import React from "react";
import {
  StyledCard,
  StyledContainer,
  StyledGridContainer,
  StyledH2,
  StyledIcon,
  StyledIconContainer,
  StyledMain,
  StyledP,
  StyledP1,
} from "./styles";
import { FaRegHandshake } from "react-icons/fa6";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { MdOutlineMoving } from "react-icons/md";
import { MdAttachMoney } from "react-icons/md";
import { PiBrainLight } from "react-icons/pi";
import { FaChartPie } from "react-icons/fa";
import { PiTargetBold } from "react-icons/pi";
const Card = ({
  icon,
  content,
  titleColor,
  contentColor,
  contentTitle,
  title,
}) => {
  return (
    <StyledCard>
      <StyledIconContainer>
        {icon && <StyledIcon>{icon}</StyledIcon>}
      </StyledIconContainer>
      <StyledP contentColor={contentColor}>{content}</StyledP>
    </StyledCard>
  );
};
function TrainingMethodology() {
  return (
    <StyledMain>
      <StyledH2>
        TRAINING <span>METHODOLOGY</span>
      </StyledH2>
      <StyledContainer>
        <Card
          content="Learning about the people"
          icon={
            <FaRegHandshake
              color="#3b3a38"
              style={{ width: "100%", height: "100%" }}
            />
          }
        />
        <Card
          content="Researching the problem"
          icon={
            <FaMagnifyingGlass
              color="#3b3a38"
              style={{ width: "100%", height: "100%" }}
            />
          }
        />
        <Card
          content="Creating Buy-in"
          icon={
            <MdAttachMoney
              color="#3b3a38"
              style={{ width: "100%", height: "100%" }}
            />
          }
        />
        <Card
          content="Developing an Implementation Plan"
          icon={
            <MdOutlineMoving
              color="#3b3a38"
              style={{ width: "100%", height: "100%" }}
            />
          }
        />
        <Card
          content={
            <div>
              {" "}
              Solving the Program
              <span> (Developing the Curriculum) </span>
            </div>
          }
          icon={
            <PiBrainLight
              color="#3b3a38"
              style={{ width: "100%", height: "100%" }}
            />
          }
        />
        <Card
          content="Evaluation"
          icon={
            <FaChartPie
              color="#3b3a38"
              style={{ width: "100%", height: "100%" }}
            />
          }
        />
        <Card
          content="Launch"
          icon={
            <PiTargetBold
              color="#3b3a38"
              style={{ width: "100%", height: "100%" }}
            />
          }
        />
      </StyledContainer>
    </StyledMain>
  );
}
export default TrainingMethodology;
