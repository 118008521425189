export const trainingData = [
  { id: 1, image: '../six.png',   title: 'Customize any Training' },
    { id: 2, image: '../one.png',   title: 'Workplace Substance Abuse' },
    { id: 3, image: '../two.png',   title: 'Anti-Harassment Training' },
    { id: 4, image: '../three.png', title: 'Information Security Training (HIPPA)' },
    { id: 5, image: '../four.png',  title: 'Workplace Safety (OSHA)' },
    { id: 6, image: '../five.png',  title: 'Intersectionality' },
    { id: 7, image: '../six.png',   title: 'Workplace Violence' },
    { id: 8, image: '../seven.png', title: 'Diversity Training' },
    
  ];
  


  export const trainingData1 = [
    { id: 1, image: '../eight.png', title: 'HR Law' },
    { id: 2, image: '../one.png',   title: 'Domestic Violence Prevention' },
    { id: 3, image: '../two.png',   title: 'Sexual Assault Prevention' },
    { id: 4, image: '../three.png', title: 'Mental Health' },
    { id: 5, image: '../four.png',  title: 'Suicide Prevention' },
    { id: 6, image: '../five.png',  title: 'SANE Training' },
    { id: 7, image: '../six.png',   title: 'SART Training' },
    { id: 8, image: '../seven.png', title: 'Law Enforcement Training' },
    
  ];
  
  export const trainingData2 = [
    { id: 1, image: '../eight.png', title: 'EEOC Training Program' },
    
  ];
  