import React from "react";
import {
  InfoSecTop,
  Image,
  StyledImage,
  InfoSecBottom,
  StyledPoints,
  DesignText,
  StyledHeading,
  StyledHeadTitle,
  StyledPara,
  NumberedList,
  DesignBackground,
  ImageSection,
  StyledImages,
  Images,
  StyledImageCenter,
  ImageGrant,
  ImageNews,
  IconRow,
  A,
  StyledIcon,
  StyledIcon5,
  StyledIcon2,
  svgTwitter,
  svgInsta,
  svgFBook,
  TextHeadingIcon,
  Text,
  InfoSectop2,
  Image133,
  HeadingGap,
} from "./style";
import Footer from "../Footer";
import Header from "../Header";
import BlogSectionData from "../../utils/BlogData";

const BlogDetail = () => {
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  return (
    <>
      <InfoSecTop>
        <Header />
        <StyledImage>
          <Image src="../imaages4.png" alt="image" />
        </StyledImage>
      </InfoSecTop>
      <InfoSectop2>
        <InfoSecBottom>
          <DesignBackground>
            <DesignText>
              <StyledHeading>
                How to <span> Finance </span> an eLearning Project?
              </StyledHeading>
              <StyledPara>{BlogSectionData.heading2}</StyledPara>
              <StyledHeadTitle>{BlogSectionData.heading3}</StyledHeadTitle>
              <NumberedList>
                {BlogSectionData.points.map((point, index) => (
                  <StyledPoints key={index} bulletColor="#EF991B">
                    <span>{point}</span>
                  </StyledPoints>
                ))}
              </NumberedList>
            </DesignText>
            <ImageSection>
              <StyledImages>
                <Images src="../imaages5.png" alt="image" />
              </StyledImages>
              <DesignText>
                <StyledHeadTitle>{BlogSectionData.heading4}</StyledHeadTitle>
                <StyledPara>{BlogSectionData.para4}</StyledPara>
                <Image133 src="../grant.png" />
                <NumberedList>
                  {BlogSectionData.points1.map((point, index) => (
                    <StyledPoints key={index} bulletColor="#EF991B">
                      <span>{point}</span>
                    </StyledPoints>
                  ))}
                </NumberedList>
              </DesignText>
              <StyledImageCenter>
                <ImageGrant src="../grant.png" />
                <ImageNews src="../news.png" />
              </StyledImageCenter>
              <DesignText>
                <StyledHeading>
                  Liam Crest <span> Disclaimer </span>{" "}
                </StyledHeading>
                <StyledPara>{BlogSectionData.para5}</StyledPara>
              </DesignText>
              <TextHeadingIcon>
                <Text>SHARE ON</Text>
                <IconRow>
                  <A href="https://www.instagram.com/liam.crest.corp/">
                    <StyledIcon>
                      <div dangerouslySetInnerHTML={{ __html: svgInsta }} />
                    </StyledIcon>
                  </A>
                  <A href="https://twitter.com/LiamCrestCorp">
                    <StyledIcon5>
                      <div dangerouslySetInnerHTML={{ __html: svgTwitter }} />
                    </StyledIcon5>
                  </A>
                  <A href="https://www.facebook.com/liamcrestcorp?_rdc=2&_rdr">
                    <StyledIcon2>
                      <div dangerouslySetInnerHTML={{ __html: svgFBook }} />
                    </StyledIcon2>
                  </A>
                </IconRow>
              </TextHeadingIcon>
            </ImageSection>
          </DesignBackground>
        </InfoSecBottom>
      </InfoSectop2>
      <HeadingGap></HeadingGap>
      <Footer />
    </>
  );
};

export default BlogDetail;
