import React from "react";
import {
  SectionContainer,
  StyledIcon,
  Logo,
  TextHeading,
  Button,
  TitleHeading,
  ParaHeading,
  StyledButton,
} from "./style";

const NewsLetter = () => {
  return (
    <SectionContainer>
      <StyledIcon>
        <Logo src="../i.png" />
      </StyledIcon>
      <TextHeading>
        <TitleHeading>Liam Crest Community Grant Program</TitleHeading>
        <ParaHeading>
          Ease the <span>Financial</span> Burden
        </ParaHeading>
      </TextHeading>
      <StyledButton>
        <a href="https://grant.liamcrest.com/" target="_blank">
          <Button>Make it Happen</Button>
        </a>
      </StyledButton>
    </SectionContainer>
  );
};

export default NewsLetter;