import styled from "styled-components";
export const StyledMain = styled.div`
  width: 100%;
  height: 776px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("bg1.png"), #f4f8f9 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 676px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 776px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 576px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 550px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: auto;
  }
`;
export const StyledH2 = styled.h2`
  color: #03375e;
  text-align: center;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 108%; /* 43.2px */
  & span {
    color: #f19101;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 108%;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 31px;
    & span {
      font-size: 31px;
    }
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 31px;
    & span {
      font-size: 31px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 30px;
    & span {
      font-size: 30px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 28px;
    & span {
      font-size: 28px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 20px;
    & span {
      font-size: 20px;
    }
  }
`;
export const StyledContainer = styled.div`
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5%;
  // margin-top: 5%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    gap: 3%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    gap: 3%;
  }
  @media only screen and (min-width: 865px) and (max-width: 1080px) {
    gap: 4%;
  }
  @media only screen and (min-width: 811px) and (max-width: 864px) {
    gap: 2%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
  }
`;
export const StyledGridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto; /* 4 cards in the first row */
  gap: 16px;
  justify-content: center;
`;
export const StyledCard = styled.div`
  width: 312px;
  height: 147px;
  flex-shrink: 0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  margin-top: 4%;
  cursor: pointer;
  background: url("img112.png") 50% / cover no-repeat, #fff;
  &:hover {
    border-radius: 20px;
    background: url("img112.png") 50% / cover no-repeat, #03375e;
    // background: #03375e;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 257px;
    height: 131px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 240.554px;
    height: 120px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 180px;
    height: 80px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 180px;
    height: 80px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 132px;
    height: 80px;
    margin-top: 15%;
  }
`;
export const StyledIconContainer = styled.div`
  width: 85px;
  height: 85px;
  flex-shrink: 0;
  position: relative;
  top: -10%;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  background-color: #dcedf4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 70px;
    height: 70px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 65px;
    height: 65px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 55px;
    height: 55px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 55px;
    height: 55px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 55px;
    height: 55px;
    top: -6%;
  }
`;
export const StyledIcon = styled.div`
  width: 51.689px;
  height: 30.488px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 40.573px;
    height: 40.859px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 43px;
    height: 43px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 30px;
    height: 30px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 30px;
    height: 30px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 30px;
    height: 30px;
  }
`;
export const StyledP = styled.p`
  color: ${(props) => props.contentColor || " #03375E"};
  text-align: center;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 28.6px */
  text-transform: capitalize;
  margin-bottom: 25%;
  & span {
    font-size: 17px;
  }
  ${StyledCard}:hover & {
    color: #fff;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 17px;
    & span {
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 14px;
    & span {
      font-size: 12px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 10px;
    & span {
      font-size: 10px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 10px;
    & span {
      font-size: 10px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 10px;
    & span {
      font-size: 10px;
    }
  }
`;
export const StyledP1 = styled.p`
  color: ${(props) => props.contentColor || " #03375E"};
  text-align: center;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 28.6px */
  text-transform: capitalize;
  margin-bottom: 25%;
  ${StyledCard}:hover & {
    color: #fff;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 10px;
  }
`;
