import React,{ useEffect, useState } from 'react';
import {
  InfoSecTop,
  Image,
  CenterImage,
  Heading,
  HeadingSec,
  HeadingTitle,
  HeadingxTitle,
  HeadingPara,
  HeadingPara1,
  ButtonText,
  ButtonHeading,
  Btn,
  Button,
  StyledCarosuel,
  StyledImage,
  StyleImage,
  DataFirst,
  DataSecond,
  InfoDiv1
} from './style';
import Header from '../Header';
import ContactFormPopup from "../Contact";
import {heroDataA,heroDataB} from '../../utils/HeroData'
import Carousel from 'react-elastic-carousel';


const HeroSection = () => {

  const [isMobile, setIsMobile] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 810);
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <InfoSecTop id="Home">
    <InfoDiv1>
       <Header/> 
     <StyledCarosuel>
       <Carousel 
                showArrows={!isMobile}
                showStatus={false}
                showThumbs={false}
                transitionTime={2000}
                stopOnHover={true}
                renderPagination={null}
                pagination={false}
         >
          
      <DataFirst>
             
        <StyledImage>
          <Image src={heroDataA.image} alt="image" />
            </StyledImage>     
              <Heading>
                <HeadingTitle>{heroDataA.headingTop}</HeadingTitle>
                  <HeadingPara>{heroDataA.headingPara}</HeadingPara>
                    <ButtonText> {isFormOpen && <ContactFormPopup onClose={closeForm} />}
                      <Btn  onClick={openForm}>{heroDataA.buttonText}</Btn>
                    </ButtonText>    
                  </Heading>
            </DataFirst>
         
            <DataFirst >    
            <StyledImage>
          <Image src={heroDataB.image} alt="image" />
            </StyledImage>     
              <Heading>
                <HeadingTitle>{heroDataB.headingTop}</HeadingTitle>
                  <HeadingPara>{heroDataB.headingPara}</HeadingPara>
                    <ButtonText> {isFormOpen && <ContactFormPopup onClose={closeForm} />}
                      <Btn  onClick={openForm}>{heroDataB.buttonText}</Btn>
                    </ButtonText>    
                  </Heading>
            </DataFirst>
           
          </Carousel>   
            </StyledCarosuel>
         
            </InfoDiv1>
    </InfoSecTop>
  );
};

export default HeroSection;
