// style.js

import styled from "styled-components";

export const InfoSecTop = styled.div`
  width: 100%;
  background: url("../down.png"), #f4f8f9 50%;

  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120vh;
  overflow: hidden;
  background-size: 100%;
  background-attachment: scroll;
  justify-content: center;
  background-position: bottom;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 90vh;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 90vh;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 90vh;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 40vh;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 31vh;
  }
`;

export const StylingBackground = styled.div`
  position: relative;
  background: #e6f3fe;
  width: 100%;
  margin: 9px;
  border-radius: 75px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 521px;
  z-index: 0;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../tst.png") center/cover;
    z-index: -1;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 426px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 386px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 307px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 245px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 171px;
  }
`;
export const StyledCarosuel = styled.div`
  width: 100%;
  margin: 0 auto;

  .rec-arrow {
    color: rgba(3, 55, 94, 1);
    border: 1px solid #ffffff !important;
    background: rgba(255, 255, 255, 1) !important;
    z-index: 0;
    margin-left: 20px;
    margin-right: 20px;
    border: none !important;
    @media only screen and (min-width: 641px) and (max-width: 810px) {
      margin-left: 0;
      margin-right: 0;
      width: 36px !important;
      height: 36px !important;
      line-height: 20px !important;
      min-width: 36px !important;
      font-size: 12px !important;
    }
    @media only screen and (min-width: 320px) and (max-width: 640px) {
      margin-left: 0;
      margin-right: 0;
      width: 25px !important;
      height: 25px !important;
      line-height: 20px !important;
      min-width: 25px !important;
      font-size: 12px !important;
    }
  }
  .rec-arrow:hover {
    color: #ffffff;
    background: #ef991b !important ;
  }

  .rec-arrow.rec-arrow-disabled {
    color: white;
    background: #ef991b;
  }
`;

export const TestimonialSec = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    margin-top: 2%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    margin-top: 2%;
  }
`;

export const CompanyLogo = styled.img`
  width: auto;
  height: auto;
  padding: 3%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: auto;
    height: auto;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: auto;
    height: auto;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: auto;
    height: 50px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: auto;
    height: 40.27px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: auto;
    height: 29.27px;
  }
`;
export const CompanyLogoB = styled.img`
  width: 166px;
  height:166px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 135.84px;
    height: 130px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 116.84px;
    height: 110px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 86.84px;
    height: 80px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 69.21px;
    height: 65px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 50.21px;
    height:45px;
  }
`;

export const TopText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
`;

export const Feedback = styled.h2`
  font-family: "Montserrat";
  font-weight: 600;
  line-height: 33.2px;
  text-align: center;
  font-size: 18px;
  color: #03375e;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 14px;
    line-height: 26.56px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 14px;
    line-height: 26.56px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    line-height: 19.56px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 8px;
    line-height: 12.56px;
  }
  @media only screen and (min-width: 421px) and (max-width: 640px) {
    font-size: 6px;
    line-height: 12.56px;
  }
  @media only screen and (min-width: 320px) and (max-width: 420px) {
    font-size: 5px;
    line-height: 8.56px;
  }
`;

export const CompanyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap:10px;
`;

export const SmallLogoB = styled.img`
  width: 89px;
  height:80.83px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 72.84px;
    height: 72px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 62.84px;
    height: 60px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 46.84px;
    height: 44px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 37.84px;
    height: 35px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 25.84px;
    height: 24px;
  }
`;

export const SmallLogo = styled.img`
  width: auto;
  height: 45.83px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: auto;
    height: 37px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: auto;
    height: 34px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: auto;
    height: 27px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: auto;
    height: 21px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: auto;
    height: 15px;
  }
`;

export const CompanyInfo = styled.div`
  text-align: center;
`;

export const CompanyName = styled.p`
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  color: rgba(3, 55, 94, 1);
  line-height: 22.05px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 12px;
    line-height: 17.64px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 12px;
    line-height: 17.64px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    line-height: 17.64px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 10px;
    line-height: 14.64px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 6px;
    line-height: 18.82px;
  }
`;

export const ComapnayPosition = styled.p`
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  color: rgba(3, 55, 94, 1);
  line-height: 22.05px;
  transform: translateY(-10px);
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 11px;
    line-height: 16.17px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 11px;
    line-height: 16.17px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 10px;
    line-height: 14.17px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 8px;
    line-height: 11.17px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 4px;
    line-height: 5.88px;
  }
`;
export const SideBarsLeft = styled.div`
  background: linear-gradient(
    to left,
    rgba(199, 235, 226, 1),
    rgba(254, 247, 230, 0)
  );

  width: 335px;
  height: 521px;
  border-radius: 74px;
  margin-left: -10%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 274px;
    height: 426px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 235px;
    height: 386px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 174px;
    height: 307px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 139px;
    height: 245px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 77px;
    height: 136px;
  }
`;
export const SideBarsRight = styled.div`
  display: flex;
  background: linear-gradient(
    91.8deg,
    rgba(239, 153, 27, 0.22) 1.87%,
    rgba(230, 254, 251, 0) 66.29%
  );
  width: 335px;
  height: 521px;
  border-radius: 74px;
  margin-right: -10%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 274px;
    height: 426px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 235px;
    height: 386px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 174px;
    height: 307px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 139px;
    height: 245px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 77px;
    height: 136px;
  }
`;
export const DataA = styled.div``;
export const DataB = styled.div``;
export const LineImages = styled.div``;
export const DataC = styled.div``;
export const DataD = styled.div``;
