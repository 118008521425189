export const TextData = {
  headingTitle: 'Program Development & Training Components',
  headingPara: 'Ignite your organizations potential with our tailored courses – whether in person, online, or a perfect blend of both! Our cutting-edge designs not only align with your goals but also deploy strategies that amplify content proficiency and retention. ',
};
export const data1Images = [
  { id: 1, image: "../img17.png", title: "Integrate AI" },
  { id: 2, image: "../img11.png", title: "Interactive Activities" },
  { id: 3, image: "../img12.png", title: "Animation" },
  { id: 4, image: "../img13.png", title: "Video" },
  { id: 5, image: "../img14.png", title: "Gamification" },
  { id: 6, image: "../img15.png", title: "Resource Databases" },
  { id: 7, image: "../img16.png", title: "Scenario Based Learning" },
];
  
  export const data2Images = [
    { id: 1, image: "../img18.png", title: "Various Learning Styles" },
    { id: 2, image: "../img19.png", title: "ADA Complaint 508" },
    { id: 3, image: "../img20.png", title: "Interactive Exercises" },
    { id: 4, image: "../img14.png", title: "Translation Available" },
    { id: 5, image: "../img15.png", title: "LGBTQ+ / LGBTQQIP2SAA Positive" },
    { id: 6, image: "../img16.png", title: "Gender Inclusive Language" },
    { id: 7, image: "../img17.png", title: "Assessments" },
  ];