import styled from 'styled-components';

export const StyledHead=styled.div`
 position: relative;
  display: flex;
height:403px;
  background: url('../sec7.png') center/cover;
  color: white;
  display: flex;
align-items:center;
  justify-content: center;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: -1;
  }
`;
export const StyledData=styled.div`

`;
export const StyledHeading=styled.div`
display:flex;
text-align:center;
justify-content: center;

`;
export const StyledPara=styled.h2`
font-size:48px;
width:65%;
font-family: Montserrat;
font-weight: 400;
letter-spacing: -0.6;
line-height:55.68px;
text-align:center;
text-transform: uppercase;
span{
    font-weight: 700;
}
@media only screen and (min-width:1281px) and (max-width:1536px){
    font-size:40px;
    line-height:46.4px;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
font-size:36px;
line-height:41.68px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
font-size:26px;
line-height: 30.16px;
}
@media only screen and (min-width:641px) and (max-width:810px){
font-size:26px;
line-height:30.16px;
}
@media only screen and (min-width:320px) and (max-width:640px){
font-size:22px;
line-height:25.19px;
}
`;
export const StyledButton=styled.div`
display:flex;
align-items: center;
flex-direction: row;
justify-content: center;
gap:10px;

`;
export const ContactButton=styled.div``;
export const ButtonClick=styled.button`
width:200px;
height:42px;
border-radius: 21px;
color:#ffffff;
background:transparent;
border:1px solid #FFFFFF;
font-size:16px;
font-family:'Montserrat';
font-weight:500;
text-align: center;
&:hover{
background-color: rgba(252, 148, 0, 1);

}
@media only screen and (min-width:1281px) and (max-width:1536px){
    width: 165.49px;
    height:34.75px;
    font-size:12px;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
    width:153px;
    height:31px;
    font-size:12px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
    width: 117.65px;
    height:37.49px;
    font-size:8px;
}
@media only screen and (min-width:641px) and (max-width:810px){
    width: 103px;
    height:25px;
    font-size:8px;
}
@media only screen and (min-width:320px) and (max-width:640px){
    width: 115px;
    height:28px;
    font-size:8px;
    line-height:8.64px;
}
`;
export const ButtonSecond=styled.div``;
export const Button2=styled.button`
width:168px;
height:64px;
border-radius: 32px;
color:#ffffff;
background:transparent;
border:1px solid #FFFFFF;
font-size:16px;
font-family:'Montserrat';
font-weight:600;
text-align: center;
&:hover{
background-color: rgba(252, 148, 0, 1);

}
@media only screen and (min-width:1081px) and (max-width:1280px){
    width:136.67px;
    height:45.49;
    font-size:12px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
    width: 115.65px;
    height:34.49px;
    font-size:10px;
}
@media only screen and (min-width:641px) and (max-width:810px){
    width: 115px;
    height:34.49px;
    font-size:10px;
}
@media only screen and (min-width:320px) and (max-width:640px){
    width: 115px;
    height:34px;
    font-size:10px;
}
`;