import React , { useState, useEffect } from 'react'
import {
StyledInfo,
HeaderLinks,
Navigation,
Logo,
Link,
MobileIcon,
LinkTop,
StyledLogo
} from './style'

import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import ContactFormPopup from "../Contact";
import { useNavigate } from 'react-router-dom';
const HeaderTop = () => {
  const navigate = useNavigate(); 
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };
  const closeMobileMenu = () => setClick(false);

  const scrollToSection = (sectionId) => {
    if (sectionId === 'Home' || sectionId === 'About') {
      if (window.location.pathname === '/') {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
          closeMobileMenu();
        }
      } else {
        navigate('/'); 
      }
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        closeMobileMenu();
      }
    }
  };
  return (
    <>
    <IconContext.Provider value={{  color: "FFFFFF" }}>
   <StyledInfo><StyledLogo src="../logo.png" alt="Logo" />
        <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
        </MobileIcon>
          
          <HeaderLinks onClick={handleClick} click={click}>  
            <Navigation>
            <Logo src="../logo.png" alt="Logo" />
            <LinkTop>  <Link onClick={() => scrollToSection("Home")}
                   style={{ cursor: "pointer" }}>Home</Link>
                        <Link onClick={() => scrollToSection("About")} style={{ cursor: "pointer" }}>About</Link>
                        {isFormOpen && <ContactFormPopup onClose={closeForm} />}
                 <Link  onClick={openForm}
                  style={{ cursor: "pointer" }} href="#">Contact</Link>
            </LinkTop>
            </Navigation>
          </HeaderLinks>
      </StyledInfo>
    </IconContext.Provider>
  </>
  )
}

export default HeaderTop