import React,{useState} from 'react'
import {
StyledHead,
StyledData,
StyledPara,
StyledHeading, 
StyledButton,
ContactButton,
ButtonClick
} from './style';
import { FaArrowRight } from "react-icons/fa6";
import ContactFormPopup from "../Contact";
const ContactUs = () => {   

    const [isFormOpen, setIsFormOpen] = useState(false);

    const openForm = () => {
      setIsFormOpen(true);
    };
  
    const closeForm = () => {
      setIsFormOpen(false);
    };

  return (
            <StyledHead id="Contact">
                <StyledData>
                    <StyledHeading>
                        <StyledPara>Maximize <span>efficiency </span>through collaboration – Together, <span>We Thrive!</span></StyledPara>
                    </StyledHeading>
                    <StyledButton>       
                        <ContactButton> {isFormOpen && <ContactFormPopup onClose={closeForm} />}
                            <ButtonClick  onClick={openForm} style={{ cursor: "pointer" }}>Let’s get started
                         <FaArrowRight
                            style={{ color: "#ffffff", position: "relative", top: "5%" ,left:"2%"}}>
                         </FaArrowRight> 
                            </ButtonClick>
                        </ContactButton>
                    </StyledButton>
                </StyledData>
            </StyledHead>
  )
}

export default ContactUs