import styled from "styled-components";

export const StyledInfo=styled.div`

`;
export const HeaderLinks = styled.header`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  @media screen and (max-width: 810px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: ${({ click }) => (click ? 0 : '-100%')};
    opacity: 0.9;
    transition: all 0.5s ease;
    background: #101522;
    z-index:2;
    padding:0;
  }
`;
export const MobileIcon=styled.div`
 display: none;

@media screen and (max-width: 810px) {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
  filter: brightness(1.2);
  z-index: 1;
}

`;
export const Navigation = styled.nav`
  display: flex;
justify-content: space-between;
  align-items: center;
  width: 97%;
  position: absolute;
  top:10px;
  @media only screen and (min-width:811px) and (max-width:1080px){
    width:95%
  }
  @media only screen and (min-width:320px) and (max-width:810px){
    flex-direction: column;
    gap:35px;
   top:30%;
  }
`;
export const StyledLogo=styled.img`
display:none;
@media only screen and (min-width:641px) and (max-width:810px){
  display:flex;
align-items:center;
justify-content: center;
padding:10px;
z-index:1;
position:absolute;
top:0;
width: 52px; 
height:52px;
left:4%;
margin-top:2%;
}
@media only screen and (min-width:200px) and (max-width:640px){
  display:flex;
align-items:center;
justify-content: center;
z-index:1;
position:absolute;
top:0;
width: 52px; 
height:52px;
padding:20px;
width: 52px; 
height:40px;
left:4%;
margin-top:2%;
}
`;

export const Logo = styled.img`
  width: 131px; 
height:93px;
display:flex;
align-items: left;
justify-content: start;
position: relative;
z-index:1;
left:4%;
margin-top:1%;
@media only screen and (min-width:1281px) and (max-width:1536px){
  width: 100px; 
height:100px;
  
}
@media only screen and (min-width:1081px) and (max-width:1280px){
   width:94.37px;
   height: 94.31px;
   }
   @media only screen and (min-width:811px) and (max-width:1080px){
    width:70.69px;
   height: 70.67px;
  }
  @media only screen and (min-width:111px) and (max-width:810px){
    display:none;
  }
`;
export const LinkTop = styled.div`
 display:flex;
  align-items: end;
  justify-content: flex-end;
  gap:40px;
margin-right:2%;
@media only screen and (min-width: 1281px) and (max-width:1536px){
  margin-right:4%;
}
@media only screen and (min-width:320px) and (max-width:810px){
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
  `;

export const Link = styled.a`
  position: relative;
  color: #FFFFFF;
  text-decoration: none;
    font-size:18px;
    font-family: 'Montserrat';
    font-weight:500;
    filter: brightness(1.2);
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background-color: #FD9400;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
    z-index:1;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: left;
  }
  @media only screen and (min-width:1081px) and (max-width:1280px){
    font-size:14px;
  }
  @media only screen and (min-width:811px) and (max-width:1080px){
    font-size:16px;
  }

`;