import React from "react";
import {
  InfoSecTop,
  StyledCarosuel,
  TestimonialSec,
  CompanyLogo,
  CompanyLogoB,
  TopText,
  Feedback,
  CompanyContainer,
  SmallLogo,
  SmallLogoB,
  CompanyInfo,
  CompanyName,
  ComapnayPosition,
  StylingBackground,
  SideBarsLeft,
  SideBarsRight,
  DataA,
  DataB,
  DataC,
  DataD,
} from "./style";
import {
  testimonialsData,
  testimonialsDataA,
  testimonialsDataB,
  testimonialsDataC,
} from "../../utils/TestimonialData";
import Carousel from "react-elastic-carousel";

const TestimonialSection = () => {
  return (
    <InfoSecTop>
      <SideBarsLeft></SideBarsLeft>
      <StylingBackground>
        <StyledCarosuel>
          <Carousel
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            dynamicHeight={false}
            emulateTouch={true}
            infiniteLoop={true}
            selectedItem={0}
            interval={5000}
            transitionTime={2000}
            stopOnHover={true}
            renderPagination={null}
            pagination={false}
          >
            <DataC>
              <TestimonialSec>
                <CompanyLogoB
                  src={testimonialsDataB.companyLogo}
                  alt="Company Logo"
                />
                <TopText>
                  <Feedback>{testimonialsDataB.TextFeedback}</Feedback>
                </TopText>
                <CompanyContainer>
                  <SmallLogoB src={testimonialsDataB.logo} alt="comp Logo" />
                  <CompanyInfo>
                    <CompanyName>{testimonialsDataB.CompanyNam}</CompanyName>
                    <ComapnayPosition>
                      {testimonialsDataB.CompanyPos}
                    </ComapnayPosition>
                  </CompanyInfo>
                </CompanyContainer>
              </TestimonialSec>
            </DataC>
            <DataD>
              <TestimonialSec>
                <CompanyLogoB
                  src={testimonialsDataC.companyLogo}
                  alt="Company Logo"
                />
                <TopText>
                  <Feedback>{testimonialsDataC.TextFeedback}</Feedback>
                </TopText>
                <CompanyContainer>
                  <SmallLogoB src={testimonialsDataC.logo} alt="comp Logo" />
                  <CompanyInfo>
                    <CompanyName>{testimonialsDataC.CompanyNam}</CompanyName>
                    <ComapnayPosition>
                      {testimonialsDataC.CompanyPos}
                    </ComapnayPosition>
                  </CompanyInfo>
                </CompanyContainer>
              </TestimonialSec>
            </DataD>
            <DataA>
              <TestimonialSec>
                <CompanyLogo
                  src={testimonialsData.companyLogo}
                  alt="Company Logo"
                />
                <TopText>
                  <Feedback>{testimonialsData.TextFeedback}</Feedback>
                </TopText>
                <CompanyContainer>
                  <SmallLogo src={testimonialsData.logo} alt="comp Logo" />
                  <CompanyInfo>
                    <CompanyName>{testimonialsData.CompanyNam}</CompanyName>
                    <ComapnayPosition>
                      {testimonialsData.CompanyPos}
                    </ComapnayPosition>
                  </CompanyInfo>
                </CompanyContainer>
              </TestimonialSec>
            </DataA>
{/* 
            <DataB>
              <TestimonialSec>
                <CompanyLogoB
                  src={testimonialsDataA.companyLogo}
                  alt="Company Logo"
                />
                <TopText>
                  <Feedback>{testimonialsDataA.TextFeedback}</Feedback>
                </TopText>
                <CompanyContainer>
                  <SmallLogoB src={testimonialsDataA.logo} alt="comp Logo" />
                  <CompanyInfo>
                    <CompanyName>{testimonialsDataA.CompanyNam}</CompanyName>
                    <ComapnayPosition>
                      {testimonialsDataA.CompanyPos}
                    </ComapnayPosition>
                  </CompanyInfo>
                </CompanyContainer>
              </TestimonialSec>
            </DataB> */}
          </Carousel>
        </StyledCarosuel>
      </StylingBackground>
      <SideBarsRight></SideBarsRight>
    </InfoSecTop>
  );
};
export default TestimonialSection;
