import React from "react";
import {
  StyledB,
  StyledBlog,
  StyledButton,
  StyledCard,
  StyledCard1,
  StyledContainer,
  StyledContainer1,
  StyledH2,
  StyledImage,
  StyledImg,
  StyledMain,
  StyledP,
  StyledP1,
  StyledP3,
  StyledText,
  StyledTextContainer,
} from "./styles";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function PostSection() {
  const navigation = useNavigate();
  const handleRedirect = (slug) => {
    return navigation(slug);
  };
  return (
    <StyledMain id="Post">
      <StyledTextContainer>
        <StyledBlog>BLOG POST</StyledBlog>
        <StyledH2>
          Latest <span>Post</span>
        </StyledH2>
      </StyledTextContainer>
      <StyledContainer>
        <StyledCard
          onClick={() => handleRedirect("/finance-elearning-project")}
        >
          <StyledImg alt="img" src="/img2.png"></StyledImg>
          <StyledText>
            <StyledP>Financing your eLearning Project</StyledP>
            <StyledP1>
              Investing into eLearning can be an expensive process..
            </StyledP1>
            <StyledButton>
              Read more <FaArrowRight></FaArrowRight>{" "}
            </StyledButton>
          </StyledText>
        </StyledCard>
        <StyledB>
          <StyledCard1 onClick={() => handleRedirect("/webinars")}>
            <StyledImage alt="img" src="/img3.png"></StyledImage>
            <StyledContainer1>
              <StyledP3>Are Webinars a form of eLearning?</StyledP3>
              <StyledButton>
                Read more <FaArrowRight></FaArrowRight>{" "}
              </StyledButton>
            </StyledContainer1>
          </StyledCard1>
          <StyledCard1 onClick={() => handleRedirect("/what-is-elearning")}>
            <StyledImage alt="img" src="/img4.png"></StyledImage>
            <StyledContainer1>
              <StyledP3>What is eLearning?</StyledP3>
              <StyledButton>
                Read more <FaArrowRight></FaArrowRight>{" "}
              </StyledButton>
            </StyledContainer1>
          </StyledCard1>
          <StyledCard1
            onClick={() => handleRedirect("/learning-management-system")}
          >
            <StyledImage alt="img" src="/img5.png"></StyledImage>
            <StyledContainer1>
              <StyledP3>What is Learning Management System</StyledP3>
              <StyledButton>
                Read more <FaArrowRight></FaArrowRight>{" "}
              </StyledButton>
            </StyledContainer1>
          </StyledCard1>
        </StyledB>
      </StyledContainer>
    </StyledMain>
  );
}

export default PostSection;
