import React from "react";
import {
  InfoSecTop,
  Image,
  StyledImage,
  InfoSecBottom,
  StyledPoints,
  DesignText,
  StyledHeading,
  StyledHeadTitle,
  StyledPara,
  NumberedList,
  DesignBackground,
  ImageSection,
  StyledImages,
  Images,
  StyledImageCenter,
  ImageGrant,
  ImageNews,
  IconRow,
  A,
  StyledIcon,
  StyledIcon5,
  StyledIcon2,
  svgTwitter,
  svgInsta,
  svgFBook,
  TextHeadingIcon,
  Text,
  InfoSectop2,
  Image133,
  HeadingGap,
} from "./style";
import Footer from "../Footer";
import Header from "../Header";
import BlogSectionData from "../../utils/BlogData";

const BlogThree = () => {
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  return (
    <>
      <InfoSecTop>
        <Header />
        <StyledImage>
          <Image src="../web4.jpeg" alt="image" />
        </StyledImage>
      </InfoSecTop>
      <InfoSectop2>
        <InfoSecBottom>
          <DesignBackground>
            <DesignText>
              <StyledHeading>
                Are <span>Webinars</span> a form of eLearning?
              </StyledHeading>
              <StyledPara>
                The trend for many organizations has been to present or give
                access to webinars via their websites. This is an awesome tool
                that allows people who can’t be in the same place to still
                receive information that they might have missed.
              </StyledPara>
              <StyledHeadTitle>Common Types of Webinars</StyledHeadTitle>
              <StyledPara>
                The presenter is able to share a PPT or a video tape of a
                meeting with their content. During this type of webinar, you
                might hear the presenter and sometimes even the attendees in the
                background. Webinars are usually saved on an organization’s
                website.
              </StyledPara>

              <StyledPara>
                Webinar programs allow you to conduct and invite people to live
                meetings. People are allowed to share files and collaborate
                throughout the presentation. These meetings are saved in a
                database within their sever.
              </StyledPara>
            </DesignText>
            <ImageSection>
              <StyledImages>
                <Images src="../web1.jpeg" alt="image"  style={{borderRadius:'12px'}}/>
              </StyledImages>
              <DesignText>
                <StyledHeadTitle>
                  Do Webinars Constitute as eLearning?
                </StyledHeadTitle>
                <StyledPara>
                  Webinars are a great introduction into the world of eLearning
                  but, it is more of a tool in the eLearning box. So many things
                  have changed and the options available for eLearning are vast
                  and plentifully. At Liam Crest, we are even starting to
                  integrate Virtual Reality into eLearning course options.
                </StyledPara>
                <Image133 src="../web2.jpeg"  style={{borderRadius:'12px'}}/>
                <StyledHeadTitle>
                  What are features of eLearning?
                </StyledHeadTitle>
                <NumberedList>
                  <StyledPoints bulletColor="#EF991B">
                    <span>
                      Customization: Design with your audience in mind.
                    </span>
                  </StyledPoints>
                  <StyledPoints bulletColor="#EF991B">
                    <span>
                      Flexibility: Trainings can be taken anywhere and at any
                      time. Learners get the full experience every time.
                    </span>
                  </StyledPoints>
                  <StyledPoints bulletColor="#EF991B">
                    <span>
                      Progress Tracking System: Track how learners are
                      progressing in real time.
                    </span>
                  </StyledPoints>
                  <StyledPoints bulletColor="#EF991B">
                    <span>
                      Reports: Pull reports for stakeholders and grant
                      organizations easily with the click of a few buttons.
                    </span>
                  </StyledPoints>
                  <StyledPoints bulletColor="#EF991B">
                    <span>
                      Interactivity: Chat and connect with learners in real
                      time.
                    </span>
                  </StyledPoints>
                  <StyledPoints bulletColor="#EF991B">
                    <span>
                      Assessment: Monitor competency and test based on learning
                      styles.
                    </span>
                  </StyledPoints>
                  <StyledPoints bulletColor="#EF991B">
                    <span>
                      Gamification: Engage learners with the most up to date
                      technology.
                    </span>
                  </StyledPoints>
                  <StyledPoints bulletColor="#EF991B">
                    <span>
                      Accessibility: 508 Compliance is essential (language and
                      ability adaptions are available).
                    </span>
                  </StyledPoints>
                  <StyledPoints bulletColor="#EF991B">
                    <span>
                      Resources: Create a database of resources so learners can
                      continuing come back for help as needed.
                    </span>
                  </StyledPoints>
                </NumberedList>
              </DesignText>
              <StyledImageCenter>
                <ImageGrant src="../web2.jpeg"  style={{borderRadius:'12px'}}/>
                <ImageNews src="../web3.jpeg"  style={{borderRadius:'12px'}}/>
              </StyledImageCenter>
            </ImageSection>
          </DesignBackground>
        </InfoSecBottom>
      </InfoSectop2>
      <HeadingGap></HeadingGap>
      <Footer />
    </>
  );
};

export default BlogThree;
