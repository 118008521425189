import React from "react";
import {
  StyledA,
  StyledB,
  StyledCard,
  StyledCard1,
  StyledIcon,
  StyledImg,
  StyledLine,
  StyledMain,
  StyledP,
  StyledP1,
  StyledP2,
  StyledP3,
  StyledP4,
  StyledSection1,
  StyledSection2,
  StyledTag,
  StyledText,
  StyledTextContainer,
  StyledTextContainer1,
} from "./styles";
import { TfiBarChart } from "react-icons/tfi";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { MdContentPaste } from "react-icons/md";
import { GiWorld } from "react-icons/gi";

const Card = ({ icon, content, info, backgroundImage, size }) => {
  return (
    <StyledCard1 size={size} backgroundImage={backgroundImage}>
      {icon && <StyledIcon>{icon}</StyledIcon>}
      <StyledP3>{content}</StyledP3>
      <StyledP4>{info}</StyledP4>
    </StyledCard1>
  );
};
function AboutSection() {
  return (
    <StyledMain id="About">
      <StyledSection1>
        <StyledCard>
          <StyledA>
            <StyledTag>About us</StyledTag>
            <StyledText>
              <span> Liam Crest leverages </span> its eLearning, curriculum
              development, , and graphic design prowess to craft compelling,
              interactive online compliance training.{" "}
              <span>
                Unleash the power of engaging digital learning with us!
              </span>
            </StyledText>
            <StyledTextContainer>
              <StyledLine></StyledLine>
              <StyledP>Let's connect</StyledP>
            </StyledTextContainer>
          </StyledA>
          <StyledB>
            <StyledImg>
              <StyledP1>15</StyledP1>
            </StyledImg>
            <StyledP2>Years of Experience</StyledP2>
            <StyledTextContainer1>
              <StyledLine></StyledLine>
              <StyledP>Let's connect</StyledP>
            </StyledTextContainer1>
          </StyledB>
        </StyledCard>
      </StyledSection1>
      <StyledSection2>
        <Card
          size="09"
          content="9 YEARS"
          info=" AMERICAN MARKET"
          backgroundImage="09.png"
          icon={<TfiBarChart style={{ width: "100%", height: "100%" }} />}
        ></Card>
        <Card
          size="15"
          content="15 YEARS"
          info="INDUSTRY EXPERIENCE"
          backgroundImage="15.png"
          icon={
            <HiMiniComputerDesktop style={{ width: "100%", height: "100%" }} />
          }
        ></Card>
        <Card
          content="10,000 HOURS"
          info="OF CONTENT"
          backgroundImage="10k.png"
          icon={<MdContentPaste style={{ width: "100%", height: "100%" }} />}
        ></Card>
        <Card
          content="30K LEARNERS"
          info="WORLDWIDE"
          backgroundImage="30K.png"
          icon={<GiWorld style={{ width: "100%", height: "100%" }} />}
        ></Card>
      </StyledSection2>
    </StyledMain>
  );
}

export default AboutSection;
