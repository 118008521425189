import HeroSection from "../HeroSection";
import Training from "../Work";
import CourseDesign from "../CourseDesign";
import ContactUs from "../ContactUS";
import Footer from "../Footer";
import Testimonial from "../Testimonial";
import AboutSection from "../AboutSection";
import TrainingMethodology from "../TrainingMethodology";
import PostSection from "../PostSection";
function Main() {
  return (
    <>
      <HeroSection />
      <TrainingMethodology />
      <AboutSection />
      <Training />
      <CourseDesign />
      <PostSection />
      <Testimonial />
      <ContactUs />
      <Footer />
    </>
  );
}

export default Main;
