import styled from "styled-components";

export const StyledMain = styled.div`
  width: 100%;
  height: 876px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("bg1.png"), #f4f8f9 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 676px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 776px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 576px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 976px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 780px;
  }
`;
export const StyledTextContainer = styled.div`
  width: 81%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 80%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    align-items: center;
  }
`;
export const StyledBlog = styled.div`
  display: flex;
  width: 106px;
  height: 26px;
  padding: 1px 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  background: rgba(241, 145, 1, 0.16);
  color: #010001;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 25.2px */
  letter-spacing: 1px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 89px;
    height: 22px;
    font-size: 12px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 115px;
    height: 26px;
    font-size: 12px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 115px;
    height: 26px;
    font-size: 10px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 115px;
    height: 26px;
    font-size: 10px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 115px;
    height: 26px;
    font-size: 10px;
  }
`;
export const StyledH2 = styled.h2`
  color: #03375e;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 108%; /* 43.2px */
  & span {
    color: #f19101;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 108%;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 36px;
    & span {
      font-size: 36px;
    }
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 36px;
    & span {
      font-size: 36px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 32px;
    & span {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 28px;
    & span {
      font-size: 28px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 20px;
    & span {
      font-size: 20px;
    }
  }
`;
export const StyledContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    flex-direction: column;
  }
`;
export const StyledCard = styled.div`
  width: 45%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 415px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 380px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 280px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 90%;
    height: 301px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
    height: 301px;
  }
`;
export const StyledImg = styled.img`
  width: 90%;
  height: 314px;
  border-radius: 3px 0px 0px 3px;
  // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 265px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 241px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 180px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 177px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 180px;
  }
`;
export const StyledText = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
export const StyledP = styled.p`
  color: var(--Grayscale-900, #121127);
  font-family: Montserrat;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 118%; /* 30.68px */
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    margin-bottom: 0;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 16px;
    margin-bottom: 0;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
    margin-bottom: 0;
  }
`;
export const StyledP1 = styled.p`
  color: #4e4d50;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 138.5%; /* 22.16px */
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 13px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 10px;
    margin-top: 0;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 12px;
    margin-top: 0;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 10px;
    margin-top: 0;
  }
`;
export const StyledButton = styled.button`
  color: #ef991b;
  border: none;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 15.4px */
  text-decoration-line: underline;
  background: none;
  width: 87px;
  height: 15px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #03375e;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 90px;
    height: 12px;
    font-size: 10px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 90px;
    height: 12px;
    font-size: 10px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 90px;
    height: 12px;
    font-size: 10px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 90px;
    height: 12px;
    font-size: 10px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90px;
    height: 12px;
    font-size: 10px;
  }
`;
export const StyledB = styled.div`
  width: 45%;
  // height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    gap: 16px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    gap: 16px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    gap: 10px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 100%;
    gap: 0px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 100%;
    gap: 0px;
  }
`;
export const StyledCard1 = styled.div`
  width: 90%;
  height: 152px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  // margin-top: 3%;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 128px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 117px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 87px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 150px;
    margin-top: 3%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 87px;
    margin-top: 3%;
  }
`;
export const StyledImage = styled.img`
  width: 40%;
  height: 119px;
  flex-shrink: 0;
  border-radius: 4px 0px 0px 3px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 101px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 92px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 69px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 119px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 69px;
  }
`;
export const StyledContainer1 = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
export const StyledP3 = styled.p`
  color: var(--Grayscale-900, #121127);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 118%; /* 28.32px */
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 11px;
  }
`;
