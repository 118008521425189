import styled from "styled-components";

export const StyledMain = styled.div`
  width: 100%;
  height: 876px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 676px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 776px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 576px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 787px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 1267px;
  }
`;
export const StyledSection1 = styled.div`
  width: 100%;
  height: 576px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: url("bg2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 450px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 526px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 376px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 466px;
    justify-content: center;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 496px;
    justify-content: center;
  }
`;
export const StyledCard = styled.div`
  width: 686px;
  height: 382px;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.88);
  box-shadow: 0px 21px 21.9px 9px rgba(0, 0, 0, 0.21);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-right: 8.2%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 589px;
    height: 353px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 545px;
    height: 382px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    margin-right: 3%;
    width: 409px;
    height: 270px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    margin-right: 0%;
    width: 409px;
    height: 270px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    margin-right: 0%;
    width: 277px;
    height: 330px;
    flex-direction: column;
    justify-content: center;
  }
`;

export const StyledA = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 3%;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
    align-items: center;
  }
`;
export const StyledTag = styled.div`
  display: flex;
  width: 96px;
  height: 22px;
  padding: 1px 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  background: #ee991c;
  color: #010001;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 25.2px */
  letter-spacing: 1px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 80px;
    height: 21px;
    font-size: 12px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 80px;
    height: 21px;
    font-size: 12px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 76.839px;
    height: 19.288px;
    font-size: 10px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 76.839px;
    height: 19.288px;
    font-size: 10px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 70px;
    height: 18px;
    font-size: 10px;
  }
`;
export const StyledText = styled.p`
  color: #03375e;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 149%;
  & span {
    font-weight: 700;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 17px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    text-align: center;
    font-size: 10px;
  }
`;
export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    display: none;
  }
`;
export const StyledTextContainer1 = styled.div`
  display: none;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
`;
export const StyledLine = styled.div`
width: 1px;
height: 36px;
flex-shrink: 0
background: #03375E;
@media only screen and (min-width: 1281px) and (max-width: 1536px) {
  height: 34px;
}
@media only screen and (min-width: 1081px) and (max-width: 1280px) {
  height: 36px;
}
@media only screen and (min-width: 811px) and (max-width: 1080px) {
  height: 28.932px;
}
 @media only screen and (min-width: 641px) and (max-width: 810px) {
   height: 28.932px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 21.267px;
  }
`;
export const StyledP = styled.p`
  color: #ee991c;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 20.8px */
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
  }
`;
export const StyledB = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
    align-items: center;
  }
`;
export const StyledImg = styled.div`
  width: 225px;
  height: 200px;
  margin-top: 16%;

  flex-shrink: 0;
  border-radius: 10px;
  background: url("image.png"), tranparent/ cover no-repeat;
  text-shadow: 0px 5px 7.2px rgba(0, 0, 0, 0.2);
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 187.5px;
    height: 184.758px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 173.506px;
    height: 200px;
    margin-top: 0%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 162px;
    height: 132px;
    margin-top: 0%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 162px;
    height: 132px;
    margin-top: 0%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 119px;
    height: 104px;
    margin-top: 0%;
  }
`;
export const StyledP1 = styled.p`
  font-family: Poppins;
  font-size: 230px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%; /* 299px */
  letter-spacing: -15px;
  background: linear-gradient(166deg, #03375e 19.56%, #f19101 112.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 200px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 190px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 150px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 150px;
    letter-spacing: -5px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 120px;
    letter-spacing: 0px;
  }
`;
export const StyledP2 = styled.p`
  color: #03375e;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 149%;
  margin-top: 16%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 16px;
    margin-top: -4%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 14px;
    margin-top: 6%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 14px;
    margin-top: 6%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
    margin-top: 0%;
  }
`;
export const StyledSection2 = styled.div`
  width: 100%;
  height: 300px;
  background: #03375e;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 8.2%;
  box-sizing: border-box;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 226px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 250px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    padding: 0 3%;
    height: 200px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    padding: 0 15%;
    height: 321px;
    flex-wrap: wrap;
    column-gap: 21%;
    row-gap: 0%;
    justify-content: center;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    padding: 0 10%;
    height: 771px;
    flex-direction: column;
  }
`;
export const StyledCard1 = styled.div`
  width: 20%;
  height: ${(props) => {
    if (props.size === "15") {
      return "120.673px";
    } else if (props.size === "09") {
      return "120px";
    } else {
      return "170px";
    }
  }};
  background-image: url(${(props) =>
    props.backgroundImage || "default_image_path.jpg"});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: ${(props) => {
      if (props.size === "15") {
        return "100.673px";
      } else if (props.size === "09") {
        return "100px";
      } else {
        return "153.673px";
      }
    }};
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: ${(props) => {
      if (props.size === "15") {
        return "84.673px";
      } else if (props.size === "09") {
        return "84.673px";
      } else {
        return "103.673px";
      }
    }};
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: ${(props) => {
      if (props.size === "15") {
        return "65.673px";
      } else if (props.size === "09") {
        return "65.673px";
      } else {
        return "83.673px";
      }
    }};
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 35%;
    height: ${(props) => {
      if (props.size === "15") {
        return "75.673px";
      } else if (props.size === "09") {
        return "75.673px";
      } else {
        return "96.178px";
      }
    }};
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 70%;
    height: 96.178px;
  }
`;
export const StyledIcon = styled.div`
  width: 86.107px;
  height: 86.052px;
  flex-shrink: 0;
  color: #ef991b;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 71.754px;
    height: 79.494px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 46px;
    height: 46px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 36px;
    height: 36px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 41.715px;
    height: 41.717px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 51.074px;
    height: 51.076px;
  }
`;
export const StyledP3 = styled.p`
  color: #fff;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 0%; /* 31.2px */
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
  }
`;
export const StyledP4 = styled.p`
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 0%;
  margin: 0;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
  }
`;
