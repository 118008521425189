export const testimonialsData = {
  companyLogo: "../logoi.webp",
  TextFeedback:
    "They were a pleasure to work with..the entire time we worked with them we felt like they listened and worked to understand our vision...we were presented with multiple options during the creation phrases...they developed a training program that was accessible to people who were blind, deaf, and hard of hearing.",
  logo: "../logoi.webp",
  CompanyNam: "Iowa Coalition Against Sexual Assault,",
  CompanyPos: "Kerri True-Funk, Associate Director",
};
   
    export const testimonialsDataA = {
      companyLogo: "../logoc.png",
      TextFeedback:
        "They were a pleasure to work with..the entire time we worked with them we felt like they listened and worked to understand our vision...we were presented with multiple options during the creation phrases...they developed a training program that was accessible to people who were blind, deaf, and hard of hearing.",
      logo: "../logoc.png",
      CompanyNam: "Iowa Coalition Against Sexual Assault, ",
      CompanyPos: "Kerri True-Funk, Associate Director",
    };
    export const testimonialsDataB = 
    {
      companyLogo: '../logoy.png',
      TextFeedback: 'What we have particularly appreciated about working with Liam Crest was their willingness to set realistic expectations and raise diﬃcult questions. This thinking, as well as Liam Crest’s impeccable responsiveness and timeliness allowed us to come out with training and guidance that was right-ﬁtted for the stage the organization is at. We appreciated how thoughtfully Liam Crest approached the structure and scaﬀolding of eLearning development, while pushing the organization to address instructional capacity.',
      logo: '../logoy.png',
      CompanyNam: 'Year Up-Senior, ',
      CompanyPos: 'Program Designer',
    };
    export const testimonialsDataC = 
    {
      companyLogo: '../logof.png',
      TextFeedback: 'I enthusiastically recommend Liam Crest to anyone looking to engage learners through online institution (eLearning courses)…Liam Crest is committed to social justice and supports organization working to end interpersonal violence. Ashley and Fred recognize the importance of focusing on underserved populations and including diversity in all aspects of project development. Liam Crest has a wide range of expertise in video production, technical application, and print material design.',
      logo: '../logof.png',
      CompanyNam: 'FCASV, ',
      CompanyPos: 'Certiﬁcation Manager',
    };
