import styled from 'styled-components';


export const StyledInfo=styled.div`
  position: relative;
  display: flex;
  height:90vh;
  background: url('../pg.png') center/cover;
  color: white;
  display: flex;
  align-items:center;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    background: rgba(3, 55, 94, 0.57);
    z-index: -1;
  }
  @media only screen and (min-width:811px) and (max-width:1080px){
    height:70vh;
    &::before{
      height:70vh;
    }
  }
  @media only screen and (min-width:320px) and (max-width:810px){
    height:100vh;
    &::before{
      height:100vh;
    }
  }
`;
export const StyledInfoHead=styled.div`
display:flex;
align-items: center;
justify-content: center;
flex-direction: column;
width:100%;
`;
export const StyledHeading=styled.div`
display:flex;
text-align:center;
justify-content: center;
align-items: center;
flex-direction: column;
transform: translateY(10%);
`;
export const StyledTitle=styled.h2`
font-size:40px;
width:100%;
font-family: "Montserrat";
font-weight: 800;
letter-spacing: -0.6;
line-height:43.2px;
text-transform: uppercase;
text-align:center;
@media only screen and (min-width:1281px) and (max-width:1536px){
font-size:30px;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
font-size:28px;
line-height: 30.24px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
font-size:20px;
}
@media only screen and (min-width:641px) and (max-width:810px){
font-size:24px;
line-height:32.19px;
}
@media only screen and (min-width:320px) and (max-width:640px){
font-size:20px;
line-height:27.19px;
width:40%;
}

`;
 
export const StyledPara=styled.p`
font-size:20px;
width:65%;
font-family: "Montserrat";
font-weight: 500;
letter-spacing: -0.6;
line-height:35.4px;
text-align:center;
@media only screen and (min-width:1281px) and (max-width:1536px){
  font-size: 16px;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
  font-size: 15px;
  line-height:26.55px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  font-size: 12px;
  line-height:21.55px;
}
@media only screen and (min-width:641px) and (max-width:810px){
  font-size: 12px;
  line-height:21.55px;
}

@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 12px;
  line-height:21.55px;
}

`;
export const StyledCarosuel=styled.div`
display:flex;
flex-direction: row;
width:100%;
margin-top: 6%;
`;
export const DataFirst=styled.div`
display: flex;
align-items: center;
flex-direction: row;
width: 90%;
@media only screen and (min-width:1081px) and (max-width:1280px){
  gap:10px;
  width:96%;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  gap:10px;
  width:96%;
}
@media only screen and (min-width:320px) and (max-width:640px){
display:grid;
grid-template-columns: repeat(3, 1fr);
}
`;
export const DataSecond=styled.div`
display: flex;
align-items: center;
flex-direction: row;
width:90%;
@media only screen and (min-width:1081px) and (max-width:1280px){

  width:96%;
}
@media only screen and (min-width:811px) and (max-width:1080px){

  width:96%;
}
@media only screen and (min-width:320px) and (max-width:640px){
display:grid;
grid-template-columns: repeat(3, 1fr);
}
`;
export const StyledIcon=styled.div`
display: flex;
align-items: center;
flex-direction: column;
width:100%;


`;
export const StyledOuter=styled.div`
display: flex;
align-items: center;
justify-content: center;
width:143px;
height:143px;
border-radius:85px;
border:3px solid #FFFFFF;
@media only screen and (min-width:1281px) and (max-width:1536px){
  width:120px;
  height:120px;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
  width:100px;
  height:100px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  width:78px;
  height:78px;
}
@media only screen and (min-width:641px) and (max-width:810px){
  width:52px;
  height:52px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  width:78px;
  height:78px;
}
`;
export const Icon=styled.img`
width:75px;
height:75px;
@media only screen and (min-width:1281px) and (max-width:1536px){
  width:62px;
height:62px;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
  width:50px;
height:50px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  width:40px;
height:40px;
}
@media only screen and (min-width:641px) and (max-width:810px){
  width:30px;
height:30px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  width:34px;
height:32px;
}
`;
export const StyledText= styled.p`
font-size: 16px;
font-family: 'Montserrat';
font-weight:700;
line-height:17.28px;
text-align: center;
width:80%;
@media only screen and (min-width:811px) and (max-width:1080px){
  font-size: 13px;
  width:100%;
}
@media only screen and (min-width:641px) and (max-width:810px){
  font-size: 10px;
  width:100%;
  line-height:12px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 10px;

  line-height:10px;
}
`;
