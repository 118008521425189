import React from "react";
import {
  InfoSecTop,
  Image,
  StyledImage,
  InfoSecBottom,
  DesignText,
  StyledHeading,
  StyledPara,
  DesignBackground,
  InfoSectop2,
  HeadingGap,
  StyledImages,
  Images,
  ImageSection,
} from "./style";
import Footer from "../Footer";
import Header from "../Header";

const BlogTwo = () => {
  return (
    <>
      <InfoSecTop>
        <Header />
        <StyledImage>
          <Image src="../blogdetfin2.jpeg" alt="image" />
        </StyledImage>
      </InfoSecTop>
      <InfoSectop2>
        <InfoSecBottom>
          <DesignBackground>
            <DesignText>
              <StyledHeading>
                What is a <span>Learning Management</span> System?
              </StyledHeading>
              <StyledPara>
                The simplest way to define a Learning Management System is to
                think of it in connection to eLearning. eLearning courses are
                created, and the Learning Management System is the place that
                houses those courses. Learning Management Systems are a learning
                environment. Each Learning Management System is different and
                allows you to utilize different features. Some can be extremely
                robust and allow you to utilize unique learning options and
                features. Others are a little more pared down and really focus
                on being a resource for materials rather than a learning
                environment. Liam Crest, an eLearning development company, works
                with organizations to determine what Learning Management System
                would be best based on their learners, approach to learning, and
                budget. We have work with the majority of Learning Management
                Systems available.
              </StyledPara>
            </DesignText>
            <ImageSection>
              <StyledImages>
                <Images src="../blogdetfin4.jpeg" alt="image" style={{borderRadius:'12px'}}/>
              </StyledImages>
            </ImageSection>
          </DesignBackground>
        </InfoSecBottom>
      </InfoSectop2>
      <HeadingGap></HeadingGap>
      <Footer />
    </>
  );
};

export default BlogTwo;
