import React from "react";
import {
  InfoSecTop,
  Image,
  StyledImage,
  InfoSecBottom,
  StyledPoints,
  DesignText,
  StyledHeading,
  StyledHeadTitle,
  StyledPara,
  NumberedList,
  DesignBackground,
  ImageSection,
  StyledImages,
  Images,
  StyledImageCenter,
  ImageGrant,
  ImageNews,
  IconRow,
  A,
  StyledIcon,
  StyledIcon5,
  StyledIcon2,
  svgTwitter,
  svgInsta,
  svgFBook,
  TextHeadingIcon,
  Text,
  InfoSectop2,
  Image133,
  HeadingGap,
} from "./style";
import Footer from "../Footer";
import Header from "../Header";
import BlogSectionData from "../../utils/BlogData";

const BlogOne = () => {
  return (
    <>
      <InfoSecTop>
        <Header />
        <StyledImage>
          <Image src="../blog2image4.jpeg" alt="image" />
        </StyledImage>
      </InfoSecTop>
      <InfoSectop2>
        <InfoSecBottom>
          <DesignBackground>
            <DesignText>
              <StyledHeading>
                What is <span> eLearning </span>?
              </StyledHeading>
              <StyledPara>
                So, what is elearning? e learning is an electronic delivery of
                education. Now the method of electronic delivery can be robust
                and diverse. Every technological resource is at the student’s
                disposal. eLearning is NOT just web conferencing. It is so much
                more: gamification, competency learning, training videos,
                including various learning styles, accessibility/508 compliance,
                learning trackers, bi-lingual education, assessing, blended
                learning and cultural representation. eLearning modules can vary
                in length, approach, and method. Educators can customize based
                on their learner’s needs.
              </StyledPara>
            </DesignText>
            <ImageSection>
              <StyledImages>
                <Images src="../blog2image.jpeg" alt="image" style={{borderRadius:'12px'}} />
              </StyledImages>
              <DesignText>
                {/* <StyledHeadTitle>{BlogSectionData.heading4}</StyledHeadTitle> */}
                <StyledPara>
                  e-learning can be a unique opportunity to provide equity and
                  individual educational practices for all learners. It can help
                  to be a real time learning tracker of learners. If a learner
                  is struggling, supplementation and mediation can be provided
                  simply, and then reassessment can be done. It is also a
                  wonderful avenue to provide various learning methods and
                  techniques to help reach the learner. Liam Crest has partnered
                  with various organizations and school districts to create
                  eLearning modules that support the standards and expertise of
                  valued educators while challenging how we present education.
                </StyledPara>
                <Image133 src="../blog2image1.jpeg" style={{borderRadius:'12px'}}/>
              </DesignText>
              <StyledImageCenter>
                <ImageGrant src="../blog2image1.jpeg" style={{borderRadius:'12px'}}/>
                <ImageNews src="../blog2image3.jpeg" style={{borderRadius:'12px'}}/>
              </StyledImageCenter>
            </ImageSection>
          </DesignBackground>
        </InfoSecBottom>
      </InfoSectop2>
      <HeadingGap></HeadingGap>
      <Footer />
    </>
  );
};

export default BlogOne;
