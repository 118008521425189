import React, { useState } from "react";
import {
  StyledInfo1,
  StyledInfo2,
  LeftSide,
  Logo,
  StyledP1,
  StyledText,
  VerticalLine,
  StyledMainHeading2,
  StyledScroll,
  StyledColumn,
  svgTwitter,
  svgInsta,
  svgLinkIn,
  svgFBook,
  svg,
  svgYoutube,
  A,
  StyledIcon1,
  StyledIcon2,
  StyledIcon3,
  StyledIcon4,
  StyledIcon5,
  StyledIcon,
  StyledColumn1,
  IconRow,
  StyledScroll1,
  StyledMainHeading3,
  StyedColumn12,
  StyedColumn13,
} from "./style";
import ContactFormPopup from "../Contact";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };
  const closeMobileMenu = () => setClick(false);

  const scrollToSection = (sectionId) => {
    if (
      sectionId === "About" ||
      sectionId === "Training" ||
      sectionId === "Post"
    ) {
      if (window.location.pathname === "/") {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
          closeMobileMenu();
        }
      } else {
        navigate("/");
      }
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        closeMobileMenu();
      }
    }
  };
  return (
    <StyledInfo1>
      <StyledInfo2>
        <LeftSide>
          <Logo src="../flogo.png" alt="logo" />
          <StyledText>
            <StyledP1>
              © 2024 <span> Liam Crest.</span>All Rights Reserved.
            </StyledP1>
          </StyledText>
        </LeftSide>

        <VerticalLine />

        <StyledColumn>
          <StyledMainHeading2> Quick Links</StyledMainHeading2>
          <StyedColumn12>
            <StyledScroll onClick={() => scrollToSection("About")}>
              About Us
            </StyledScroll>
            <StyledScroll onClick={() => scrollToSection("Training")}>
              Our Services
            </StyledScroll>
            {isFormOpen && <ContactFormPopup onClose={closeForm} />}
            <StyledScroll onClick={openForm}>Contact Us</StyledScroll>
            <StyledScroll onClick={() => scrollToSection("Post")}>
              Blog Post
            </StyledScroll>
          </StyedColumn12>
        </StyledColumn>

        <VerticalLine />

        <StyledColumn1>
          <StyledMainHeading3>
            <IconRow>
              <A href="https://www.instagram.com/liam.crest.corp/">
                <StyledIcon>
                  <div dangerouslySetInnerHTML={{ __html: svgInsta }} />
                </StyledIcon>
              </A>
              <A href="https://twitter.com/LiamCrestCorp">
                <StyledIcon5>
                  <div dangerouslySetInnerHTML={{ __html: svgTwitter }} />
                </StyledIcon5>
              </A>
              <A href="https://www.linkedin.com/company/liamcrest">
                <StyledIcon1>
                  <div dangerouslySetInnerHTML={{ __html: svgLinkIn }} />
                </StyledIcon1>
              </A>
              <A href="https://www.facebook.com/liamcrestcorp?_rdc=2&_rdr">
                <StyledIcon2>
                  <div dangerouslySetInnerHTML={{ __html: svgFBook }} />
                </StyledIcon2>
              </A>
              <A href="https://www.behance.net/designers-liamcrest">
                <StyledIcon3>
                  <div dangerouslySetInnerHTML={{ __html: svg }} />
                </StyledIcon3>
              </A>
              <A href="https://www.youtube.com/channel/UCVYVnbFIbWQ4a3MfPyMhQHw">
                <StyledIcon4>
                  <div dangerouslySetInnerHTML={{ __html: svgYoutube }} />
                </StyledIcon4>
              </A>
            </IconRow>
          </StyledMainHeading3>
          <StyedColumn13>
            <StyledScroll1>888 491 5293</StyledScroll1>
            <StyledScroll1>Memphis, TN and Phoenix, AZ</StyledScroll1>
            <StyledScroll1>Sales@liamcrest.com</StyledScroll1>
          </StyedColumn13>
        </StyledColumn1>
      </StyledInfo2>
    </StyledInfo1>
  );
};

export default Footer;
