import React, { useEffect, useState } from "react";
import {
  StyledCard,
  StyledCardRow,
  StyledTitle,
  StyledIcon,
  StyledCardBackground,
  StyledMain,
  StyledPara,
  StyledRow,
  StyledServices,
  StyledText,
  Logo,
  StyledCarosuel,
  DataFirst,
  DataSecond,
  DataThird
} from "./style";
import NewsLetter from "../NewsLetter";
import Carousel from 'react-elastic-carousel';
import {trainingData1,trainingData2,trainingData} from '../../utils/WorkCardData';

const Training = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth >= 641 && window.innerWidth <= 810);
    };

    handleResize(); // Call on initial render
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <StyledMain id="Training">
        <StyledText>
          <StyledTitle>
            TRAINING <span> SPECIALIZATIONS</span>
          </StyledTitle>
        </StyledText>
        <StyledCarosuel>
          <Carousel
            showArrows={!isMobile}
            showStatus={false}
            showThumbs={false}
            dynamicHeight={false}
            emulateTouch={true}
            infiniteLoop={true}
            selectedItem={0}
            interval={5000}
            transitionTime={2000}
            stopOnHover={true}
            renderPagination={null}
            pagination={false}
          >
            <DataFirst>
              <StyledServices>
                <StyledRow>
                  {trainingData.slice(0, isMobile ? 3 : 4).map((item) => (
                    <StyledCard key={item.id}>
                      <StyledIcon>
                        <StyledCardBackground>
                          <Logo
                            src={item.image}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </StyledCardBackground>
                      </StyledIcon>
                      <StyledPara>{item.title}</StyledPara>
                    </StyledCard>
                  ))}
                </StyledRow>
                <StyledRow>
                  {trainingData.slice(4, isMobile ? 7 : 8).map((item) => (
                    <StyledCardRow key={item.id}>
                      <StyledIcon>
                        <StyledCardBackground>
                          <Logo
                            src={item.image}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </StyledCardBackground>
                      </StyledIcon>
                      <StyledPara>{item.title}</StyledPara>
                    </StyledCardRow>
                  ))}
                </StyledRow>
              </StyledServices>
            </DataFirst>
            <DataSecond>
              <StyledServices>
                <StyledRow>
                  {trainingData1.slice(0, isMobile ? 3 : 4).map((item) => (
                    <StyledCard key={item.id}>
                      <StyledIcon>
                        <StyledCardBackground>
                          <Logo
                            src={item.image}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </StyledCardBackground>
                      </StyledIcon>
                      <StyledPara>{item.title}</StyledPara>
                    </StyledCard>
                  ))}
                </StyledRow>
                <StyledRow>
                  {trainingData1.slice(4, isMobile ? 7 : 8).map((item) => (
                    <StyledCardRow key={item.id}>
                      <StyledIcon>
                        <StyledCardBackground>
                          <Logo
                            src={item.image}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </StyledCardBackground>
                      </StyledIcon>
                      <StyledPara>{item.title}</StyledPara>
                    </StyledCardRow>
                  ))}
                </StyledRow>
              </StyledServices>
            </DataSecond>
            <DataThird>
              <StyledServices>
                <StyledRow>
                  {trainingData2.slice(0, isMobile ? 3 : 4).map((item) => (
                    <StyledCard key={item.id}>
                      <StyledIcon>
                        <StyledCardBackground>
                          <Logo src={item.image} style={{ width: '100%', height: '100%' }} />
                        </StyledCardBackground>
                      </StyledIcon>
                      <StyledPara>{item.title}</StyledPara>
                    </StyledCard>
                  ))}
                </StyledRow>
                <StyledRow>
                  {trainingData2.slice(4, isMobile ? 7 : 8).map((item) => (
                    <StyledCardRow key={item.id}>
                      <StyledIcon>
                        <StyledCardBackground>
                          <Logo src={item.image} style={{ width: '100%', height: '100%' }} />
                        </StyledCardBackground>
                      </StyledIcon>
                      <StyledPara>{item.title}</StyledPara>
                    </StyledCardRow>
                  ))}
                </StyledRow>
              </StyledServices>
            </DataThird>
          </Carousel>
        </StyledCarosuel>
        <NewsLetter />
      </StyledMain>
    </>
  );
};

export default Training;