import styled from "styled-components";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 87%;
  height: 158px;
  background: #f19101;
  margin: 0% 2%;
  border-radius: 10px;
  position: relative;
  /* top: -24%; */
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: url("../inf.png") center/cover;
    width: 100%;
    height: 158px;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2.5%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 167px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -20%;
    height: 256px;
    &::before {
      height: 256px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -15%;
    height: 174px;
    &::before {
      height: 174px;
    }
  }
`;
export const StyledIcon = styled.div`
  width: 75px;
  height: 75px;
  margin-left: 5%;
  @media only screen and (min-width: 640px) and (max-width: 810px) {
    margin-top: 5%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
  }
`;
export const Logo = styled.img`
  @media only screen and (min-width: 811px) and (max-width: 1280px) {
    width: 56px;
    height: 56px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 52px;
    height: 56px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 45px;
    height: 43px;
  }
`;
export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  width: 85%;
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 25%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 25%;
  }
`;
export const Button = styled.button`
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: center;
  width: 219px;
  height: 63px;
  border-radius: 31.5px;
  border: none;
  background: #03375e;
  color: #ffffff;
  cursor: pointer;

  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 186px;
    height: 52px;
    font-size: 14px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 196px;
    height: 63px;
    font-size: 14px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 143px;
    height: 53px;
    font-size: 12px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 143px;
    height: 47px;
    font-size: 12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 130px;
    height: 30px;
    font-size: 8px;
  }
`;
export const TextHeading = styled.div`
  width: 241%;
  margin-left: 2%;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    margin-top: -2%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 80%;
    margin-top: -3%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 73%;
    margin-top: -8%;
  }
`;
export const TitleHeading = styled.p`
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 22.4px;
  color: rgba(255, 255, 255, 0.8);
  transform: translateY(55%);
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 20px;
    line-height: 20.4px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 16px;
    line-height: 17.92px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 14px;
    line-height: 15.8px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 14px;
    line-height: 15.8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 10px;
    line-height: 11.2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const ParaHeading = styled.h2`
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 28px;
  line-height: 22.4px;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  transform: translateY(-5%);
  span {
    color: rgba(3, 55, 94, 1);
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 20px;
    line-height: 21.4px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 24px;
    line-height: 25.92px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 18px;
    line-height: 19.44px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 18px;
    line-height: 19.44px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 14px;
    line-height: 15.22px;
    width: 108%;
    text-align: center;
  }
`;
