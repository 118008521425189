import React, { useState, useEffect } from 'react';
import {
  StyledInfo,
  StyledInfoHead,
  StyledHeading,
  StyledTitle,
  StyledPara,
  StyledCarosuel,
  DataFirst,
  DataSecond,
  StyledIcon,
  StyledOuter,
  Icon,
  StyledText
} from './style';
import Carousel from 'react-elastic-carousel';
import { data1Images, data2Images, TextData } from '../../utils/CourseDesignData';
import CustomDot from '../../utils/CustomDot';

const CourseDesign = () => {
  const [iconCount, setIconCount] = useState(5); 

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 640) {
        setIconCount(6); 
      } else if (screenWidth >= 640 && screenWidth < 810) {
        setIconCount(5); 
      } else {
        setIconCount(data1Images.length); 
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <StyledInfo>
      <StyledInfoHead>
        <StyledHeading>
          <StyledTitle>{TextData.headingTitle}</StyledTitle>
          <StyledPara>{TextData.headingPara}</StyledPara>
        </StyledHeading>
        <StyledCarosuel>
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            dynamicHeight={false}
            emulateTouch={true}
            infiniteLoop={true}
            selectedItem={0}
            interval={5000}
            transitionTime={2000}
            stopOnHover={true}
            renderPagination={({ pages, activePage, onClick }) => (
              <div style={{ textAlign: 'center', marginTop: '-5px' }}>
                {pages.map((page) => (
                  <CustomDot key={page} onClick={() => onClick(page)} isActive={page === activePage} />
                ))}
              </div>
            )}
          >
            <DataFirst>
              {data1Images.slice(0, iconCount).map((item, index) => (
                <StyledIcon key={index}>
                  <StyledOuter>
                    <Icon src={item.image} />
                  </StyledOuter>
                  <StyledText>{item.title}</StyledText>
                </StyledIcon>
              ))}
            </DataFirst>

            <DataSecond>
              {data2Images.slice(0, iconCount).map((item, index) => (
                <StyledIcon key={index}>
                  <StyledOuter>
                    <Icon src={item.image} />
                  </StyledOuter>
                  <StyledText>{item.title}</StyledText>
                </StyledIcon>
              ))}
            </DataSecond>
          </Carousel>
        </StyledCarosuel>
      </StyledInfoHead>
    </StyledInfo>
  );
};

export default CourseDesign;
