const BlogSectionData = {
    heading2:'Investing into eLearning can be an expensive process depending on the scope of your project and the implementation process needed to ensure its success. This can seem very daunting and overwhelming. But we have got you covered.',
    heading3:'Before hiring an eLearning Company Ask the following questions:',
    points: [
      'Do they have discounts for small business and nonprofits?',
      'Do they offer grants or are aware of grants to help supplement costs?',
      'Do they offer payment plans? Be sure to determine when you will get the content if they do.',
      'Do they offer loans and if so, are they interest free?',
      'What happens if you cannot pay?',
      'Do they offer discounts for repeat customers?',
      'Are their issues with working within grant cycles or finance budgets?',
      'Do they have grant writers on staff who can research and help you?',
      
    ],
    heading4:'Are grants available?',
    para4:'There are 100s of thousands of grants available for eLearning and training. Many of these grants can be general for any organization to apply and some can be industry specific such as for nonprofits who service children. Here are some suggestions to help find grants.',
    points1:[
      'Find an eLearning company that offers them.',
      'Research organizations online that offer grants.',
      'Buy an eLearning grant book online',
      'Hire a grant writer/researcher (some of them don’t get paid unless you get the grant, or they offer a small fee in the front and a bigger fee in the backend).',
    ],
    para5:'*Please note these are grant options based on Liam Crest capabilities. All eLearning organizations are different. Contact us if you have any questions about financing your eLearning program.*',
  };
  
  export default BlogSectionData;
  