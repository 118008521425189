// import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BlogDetail from "./components/BlogDetail";
import Main from "./components/Main";
import ScrollToTop from "./components/Scroll";
import BlogOne from "./components/BlogOne";
import BlogTwo from "./components/BlogTwo";
import BlogThree from "./components/BlogThree";
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/finance-elearning-project" element={<BlogDetail />} />
        <Route path="/what-is-elearning" element={<BlogOne />} />
        <Route path="/learning-management-system/" element={<BlogTwo />} />
        <Route path="/webinars" element={<BlogThree />} />
      </Routes>
    </Router>
  );
}

export default App;
