import React from 'react';

const CustomDot = ({ onClick, isActive }) => (
  <div
    style={{
      width: isActive ? '15px' : '15px',
      height: isActive ? '15px' : '15px',
      margin: '40px 3px',
      borderRadius: '50%',
      background: isActive
        ? 'linear-gradient(to right, rgba(220, 237, 244, 1), rgba(239, 153, 27, 1))'
        : 'transparent',
      border: isActive ? 'none' : '1px solid #FFFFFF',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease-in-out',
      display: 'inline-block',
    }}
    onClick={onClick}
  />
);

export default CustomDot;
